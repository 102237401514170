import _ from 'lodash'

import { usePage } from '../hooks/resourceHooks'
import { fetchResources } from '../store/modules/resources/actions'
import Router from 'next/router'

import Error from './_error'
import ContentfulPage from '../containers/ContentfulPage'

const DynamicContentfulPage = ({ errorCode, pathname }) => {
  const page = usePage(pathname)

  if (errorCode || _.isEmpty(page)) {
    return <Error statusCode={errorCode || 404} />
  }

  return (
    <ContentfulPage pathname={pathname} />
  )
}

DynamicContentfulPage.getInitialProps = async ({ res, store: { dispatch }, query }) => {
  const pathname = _.get(query, 'dynamicContentfulPage').replace(/\//g, '')

  if (!pathname) {
    res.statusCode = 500
    return { errorCode: 500 }
  }

  if (_.toLower(pathname) === 'idaps') {
    if (res) {
      res.writeHead(301, { Location: '/idaps' })
      res.end()
    } else {
      Router.replace('/idaps')
    }
  }

  const result = await dispatch(fetchResources({
    resourceType: 'page',
    requestKey: pathname,
    where: {
      'fields.pageUrlName': pathname
    }
  }))

  const resources = _.get(result, 'resources')
  if(_.isEmpty(resources)) {
    res.statusCode = 404
  }

  return { pathname }
}

export default DynamicContentfulPage
